import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'modal',
    'webpSrc',
    'jpegSrc'
  ]

  show(event) {
    this.modalTarget.classList.remove('hidden')
    const { webpSrc, jpegSrc } = event.params
    this.webpSrcTarget.srcset = webpSrc
    this.jpegSrcTarget.src = jpegSrc
  }

  hide() {
    this.modalTarget.classList.add('hidden')
    this.webpSrcTarget.srcset = ''
    this.jpegSrcTarget.src = ''
  }

}
