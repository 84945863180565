import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'button'
  ]

  connect() {
    if (navigator.share) {
      this.buttonTarget.classList.remove('hidden')
    }
  }

  async show() {
    if (!navigator.share) return
    const shareData = {
      title: "Zeta's Yogurt",
      text: "Authentic All Natural Greek Yogurt",
      url: window.location.href
    }
    try {
      await navigator.share(shareData)
    } catch (error) {
      console.log('Error sharing', error)
    }
  }

}
